html {
  background: #e2d3d3;
  position: fixed;
  height: 100%;
  width: 100%;
  font-family: 'Lora', serif;
  font-weight: 400;

}
* {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

#Top_of_letter_2_ {
  position: absolute;
  top: 0; left: 0; right: 0;
  z-index: -1;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}